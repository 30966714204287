 <template>
 <svg style="width: 26px; height: 26px;" xmlns='http://www.w3.org/2000/svg' class='ionicon inline' viewBox='0 0 512 512'><title>Mail</title><rect x='48' y='96' width='416' height='320' rx='40' ry='40' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M112 160l144 112 144-112'/></svg>
</template>

<script>
export default {
  name: 'EmailSvg',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
