import { render, staticRenderFns } from "./ErrorSvg.vue?vue&type=template&id=308d8121&scoped=true&"
import script from "./ErrorSvg.vue?vue&type=script&lang=js&"
export * from "./ErrorSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308d8121",
  null
  
)

export default component.exports