<template>
  <div id="app">
     <div style="background-color: #005BAB" class="header shadow-lg px-4 py-5">
        <div class="logo m-auto text-center">
          <LogoSvg />
        </div>
      </div>

      <div  class="main h-full w-full">

        <div class="md:flex-shrink-0 rounded-lg">
          <img :src="src" height="750" width="1000"/>
        </div>
        <div class="thanks text-center my-10 px-4">
          <h1 style="font-size: 24px;" class="font-bold text-gray-800 tracking-normal">{{ PageThanks }}</h1>
        </div>
        <div class="w-5/6 m-auto text-left">
          <span class="my-2 block">選択してください：</span>
          <v-select class="style-chooser" :options="selectOption" @input="changeType" :placeholder="currentType.label" :value="currentType" ></v-select>
        </div>
        <div v-show="show.club" class="card w-5/6 bg-white mx-auto shadow my-10 rounded-lg">
           <div class="px-4 py-2 mt-2">
              
              <div v-html="ClubInvitation" class="text-lg text-gray-700 px-2 "></div>

              <div class="m-auto text-center py-8">
                <button @click="onSentEmail" class="mr-5 font-bold text-lg  bg-green-600 hover:bg-green-700 text-white  py-4 px-6 rounded-lg">
                  <EmailSvg />
                  メールを送る
                </button>
              </div>
           </div>
        </div> 



        <div v-show="show.sales" class="card w-5/6 bg-white mx-auto shadow  my-10 mb-0 rounded-lg">
          <form>
          <div class="font-bold text-base text-left pt-4 text-gray-800 tracking-normal px-4 py-2 mt-2">

            <h2 v-if="currentType.value == 2">{{ AfterSales.title }}:</h2>
            <h2 v-else-if="currentType.value == 3">{{ FriendlyList.title }}:</h2>
          </div>
          <div class="px-4 py-2 mt-2 text-left">
            <div class="mt-2 mb-6 relative rounded-md shadow-sm">
              <div class="items-center pointer-events-none">
                  <span>あなたの:</span>
              </div>
              <input required name="username" v-model="fromData.username" v-bind:class="{'border-red-600': fromValidate.username, 'zms-input': true }"  placeholder="あなたの">
              <p class="text-xs text-red-600" v-show="fromValidate.username">名前を入力してください。</p>
            </div>

            <div class="mt-2 mb-6 relative rounded-md shadow-sm">
              <div class="items-center pointer-events-none">
                  <span>連絡先:</span>
              </div>
              <input required name="email" v-model="fromData.email" v-bind:class="{'border-red-600': fromValidate.email, 'zms-input': true }"   placeholder="メールアドレスまたは電話番号">
              <p class="text-xs text-red-600" v-show="fromValidate.email">連絡先を入力してください。</p>
            </div>

            <div class="mt-2 mb-6 relative rounded-md shadow-sm">
              <div class="items-center pointer-events-none">
                <span>注文番号:</span>
              </div>
              <input required name="number" v-model="fromData.number" class="zms-input"   placeholder="注文番号を入力してください">
              <p class="text-xs text-red-600" v-show="fromValidate.email">注文番号</p>
            </div>

            <div v-if="currentType.value==2" class="mt-2 mb-6 relative rounded-md shadow-sm">
              <div class="items-center pointer-events-none">
                  <span>詳細に説明する:</span>
              </div>
              <textarea v-model="fromData.message" required name="message" class="zms-textarea" placeholder="あなたが発生した問題を説明してください。">
               </textarea>
              <!-- <p class="text-xs text-red-600" v-show="fromValidate.message">詳細を入力してください。</p> -->
            </div>

            <div v-show="submitMessage.success" class="py-4 rounded-2xl px-1 text-gray-500 bg-green-200 ">
              <span>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid " class="svg" style="width: 24px; height: 24px; display: inline-block">
                  <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                  </g>
                  <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#AE4FF8" stroke="none">
                  <path class="node" id="node2" d="M2145 2246 c-16 -8 -219 -203 -450 -435 -231 -232 -424 -421 -430
                  -421 -5 0 -101 91 -213 203 l-202 202 -50 0 -50 0 -122 -122 -122 -122 0 -51
                  0 -51 350 -349 c240 -240 358 -351 378 -356 16 -4 43 -3 61 2 46 13 1179 1132
                  1195 1180 21 62 1 99 -112 211 -126 124 -161 141 -233 109z" style="fill: rgb(39, 229, 93);"></path>
                  </g>
                  </svg>
                  提出成功！最初の時間に連絡します!
              </span>
            </div>

            <div v-show="submitMessage.error" class="py-4 rounded-2xl px-1 text-gray-500 bg-red-200 ">
              <span v-show="submitMessage.error">
                <ErrorSvg style="display: inline-block; width: 24px; height: 24px;" />
                  フォームが完全であり、フォーマットが正しいか確認してください
               </span>
            </div>


             <div class="mt-2 mb-6 ">
               <button @click="onSubmit" :disabled="submitMessage.loading" type="button" :class="{'submit-button': true, 'is-disabled': submitMessage.loading  }">
                 <svg v-show="submitMessage.loading" class="animate-spin mr-1 inline-block h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                   <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                   <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                 </svg>
                  提出する
                </button>
            </div>
          </div>
          </form>
        </div>

        <div class="pt-10 pb-5 font-sm">
          <span>Copyright ©sales.kaiyopop.com 2010 - 2021  All Rights Reserved.</span>
        </div> 

      </div>
  </div>
</template>

<script>
import LogoSvg from './components/LogoSvg.vue'
import EmailSvg from './components/EmailSvg.vue'
import AxiosInstance from "@/plugin/axios";
import ErrorSvg from "@/components/ErrorSvg";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'App',
  components: {
    ErrorSvg,
    LogoSvg,
    EmailSvg,
    vSelect
  },
  data () {
    return {
      selectOption: [
        {label: '弊社のVIPメンバーになりたいですか', value: 1},
        {label: '製品について問題がありましたか', value: 2},
        {label: 'オマケを受け取りたいですか', value: 3}
      ],
      currentType: {label: '', value: 0},
      show: {
        sales: false,
        thanks: false,
        club: true
      },
      src: require('./assets/aquarium.png'),
      emailSrc: require('./assets/email.png'),
      PageThanks: '私たちの製品を注文してくれてありがとうございます!',
      FriendlyList: {
         title: 'よろしければ、大丈夫です',
         content: [
             {message: 'アマゾンで私たちに完全な商品評価をしてくれます。'},
             {message: ' お友達にこの商品を紹介します。'},
         ]
      },
      AfterSales: {
        title: 'もしどんな製品に問題があったら、まず連絡してください。誠心誠意サービスします',
        content: '',
      },
      ClubInvitation: '<h2 class="font-bold text-2xl text-left mt-5 text-gray-800 tracking-normal">お客様、こんにちは:</h2>' +
          '<p style="text-indent: 2em" class="mt-4">私達は心から会員クラブに参加していただきたいです。ここでは専門のカスタマーサービスの接待、商品購入から一年間の保証政策、最新の活動情報とセールなどを楽しむことができます。</p>',
      type: window.urlParamType,
      fromData: {
        username: '',
        email: '',
        message: '',
        number: '',
      },
      fromValidate: {
        username: false,
        email: false,
      },
      showSuccess: false,
      submitMessage: {
        loading: false,
        success: false,
        error: false
      },
      controlStatus: false,
    }
  },
  mounted() {

  },
  methods: {
      getQuery (variable) {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
          let pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
        }
        return(false);
      },
      onSentEmail () {
          window.location.href = 'mailto:kaiyopop@hotmail.com';
      },
      onSubmit () {
        this.controlStatus = false
        this.submitMessage.loading = true
        this.checkFormValidate(this.fromData)

        for (let k in this.fromValidate) {
          if (this.fromValidate[k] == true) {
            this.submitMessage.success = false
            this.submitMessage.error = true
            this.submitMessage.loading = false
            return false;
          }
        }


        console.log(213);

        let that = this;
        let data = that.fromData
        data.type = this.currentType.value
        AxiosInstance.post('/amazon_sales', data).then((response) => {
          this.submitMessage.loading = false
          if (response.data.code === 1) {
            that.controlStatus = true
            that.fromData = {
              username: '',
              email: '',
              message: '',
              number: '',
            }
            that.submitMessage.success = true
            that.submitMessage.error = false
          }else{
            that.submitMessage.success = false
            that.submitMessage.error = true
          }

        })

        console.log('submit');

      },
      checkFormValidate (data) {
        if (this.controlStatus ) {
          return false
        }
        for (let k in data) {
          if (data[k] == '') {
            this.fromValidate[k] = true;
          }else{
            this.fromValidate[k] = false;
          }
        }
      },
      changeType (val) {
        console.log(val)
        console.log(val.value)
        let type = val.value
        for(let i=0; i<this.selectOption.length; i++) {
          if (this.selectOption[i].value == type) {
            this.currentType = this.selectOption[i];
            break;
          }
        }
        switch (type) {
          case 1:
            this.show.club = true
            this.show.sales = false
            this.show.thanks = false
            break;
          case 2:
          case 3:
            this.show.sales = true
            this.show.club = false
            this.show.thanks = false
            break;

          default:
            this.show.thanks = false
            this.show.sales = false
            this.show.club = true
        }


      }
  },
  watch: {
    fromData: {
      handler(newVal, oldVal) {
        this.checkFormValidate(newVal)
        console.log(oldVal)
        return newVal
      },
      deep: true,
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #d6ecf3;
}
.zms-input { 
  @apply block shadow appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 mb-3 leading-tight  transition duration-500 ease-in-out
 }
 .zms-input:focus {
  @apply bg-white  outline-none
 }

 .zms-textarea{
  @apply block shadow appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 h-32 mb-3 leading-tight transition duration-500 ease-in-out
 }
 .zms-textarea:focus{ 
  @apply outline-none bg-white 
 }

 .submit-button {
   @apply mr-5 font-bold text-lg w-full bg-blue-600  text-white  py-2 px-4 rounded-lg
 }
 .submit-button:hover {
   @apply outline-none bg-blue-700
 }
 .is-disabled {
   opacity: 0.3;
   @apply cursor-not-allowed
 }
 .style-chooser .vs__dropdown-toggle {
   border: 2px solid dimgrey;
 }
</style>
